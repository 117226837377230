import { styled } from '@joovence/ui/config/styled';

export const DividerStyled = styled('div')(({ theme }) => ({
  width: '100%',
  height: 1,

  margin: theme.spacing(12, 0, 8, 0),

  backgroundColor: theme.palette.grey.A400
}));
