export const JoovenceLogoLarge = (
  <svg
    width="274"
    height="30"
    viewBox="0 0 274 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1253_37777)">
      <path
        d="M0.856934 24.8067L4.26305 21.3972C5.98591 23.5617 7.70877 24.7684 10.1841 24.7684C12.9566 24.7684 14.8577 22.9679 14.8577 19.1562V0.519287H20.2045V19.3095C20.2045 22.6806 19.1549 25.2089 17.432 26.8753C15.7092 28.5417 13.2338 29.4037 10.2832 29.4037C5.72847 29.4037 2.79763 27.3733 0.856934 24.8067Z"
        fill="white"
      />
      <path
        d="M98.0503 0.519287H103.833L112.784 22.5848L121.695 0.519287H127.339L115.041 29.4228H110.427L98.0503 0.519287Z"
        fill="white"
      />
      <path
        d="M139.854 0.519287H162.014V5.05881H145.122V12.5672H160.113V17.1067H145.122V24.8641H162.232V29.4037H139.854V0.519287Z"
        fill="white"
      />
      <path
        d="M174.529 0.519287H179.361L195.283 20.4012V0.519287H200.412V29.2696H196.035L179.678 8.81301V29.2696H174.529V0.519287Z"
        fill="white"
      />
      <path
        d="M212.986 14.9807V14.9041C212.986 6.95518 219.145 0.461945 227.878 0.461945C233.205 0.461945 236.413 2.26243 239.186 4.80992L235.918 8.4492C233.601 6.38056 231.126 4.98231 227.839 4.98231C222.353 4.98231 218.313 9.3686 218.313 14.8467V14.9233C218.313 20.3822 222.314 24.826 227.839 24.826C231.383 24.826 233.661 23.466 236.136 21.225L239.404 24.4237C236.393 27.4501 233.086 29.3655 227.68 29.3655C219.244 29.3655 212.986 23.0063 212.986 14.9807Z"
        fill="white"
      />
      <path
        d="M251.682 0.519287H273.782V5.05881H256.929V12.5481H271.881V17.0684H256.929V24.8067H274V29.3462H251.682V0.519287Z"
        fill="white"
      />
      <path
        d="M55.0181 22.2021L58.0678 25.1518C55.2558 27.8717 51.5328 29.3657 47.5722 29.3657C43.5918 29.3657 39.8688 27.8717 37.0766 25.1518C31.2941 19.5588 31.2941 10.4606 37.0766 4.84845C39.8688 2.12857 43.5918 0.634552 47.5722 0.634552C51.5328 0.634552 55.2558 2.12857 58.0678 4.84845L60.0481 6.76386L56.9984 9.69444L55.0181 7.77903C53.0378 5.86362 50.3842 4.81014 47.5722 4.81014C44.7602 4.81014 42.1264 5.86362 40.1065 7.79818C36.0072 11.7631 36.0072 18.218 40.1065 22.1829C42.1066 24.0983 44.7602 25.1709 47.5722 25.1709C50.2258 25.1709 52.721 24.1941 54.6815 22.4511C54.8003 22.3744 54.9191 22.2978 55.0181 22.2021Z"
        fill="white"
      />
      <path
        d="M47.5718 29.5954C43.532 29.5954 39.7298 28.0822 36.898 25.3241C34.0464 22.5659 32.4819 18.9074 32.4819 15C32.4819 11.0926 34.0464 7.43412 36.898 4.67593C39.7496 1.91775 43.532 0.404572 47.5718 0.404572C51.5919 0.404572 55.394 1.91775 58.2457 4.67593L60.4042 6.76373L56.9981 10.0391L54.8396 7.95129C52.8989 6.07418 50.3245 5.03986 47.5718 5.03986C44.8192 5.03986 42.2448 6.07418 40.2843 7.95129C38.3436 9.82839 37.2743 12.3184 37.2743 14.9808C37.2743 17.6433 38.3436 20.1333 40.2843 22.0104C42.2448 23.8875 44.839 24.9218 47.5718 24.9218C50.1264 24.9218 52.582 23.9833 54.5029 22.2594C54.5623 22.2211 54.6019 22.1828 54.6415 22.1636C54.7009 22.1062 54.7801 22.0679 54.8198 22.0104L54.998 21.7997L58.4239 25.1134L58.2457 25.2857C55.394 28.0822 51.6117 29.5954 47.5718 29.5954ZM47.5718 0.883424C43.6508 0.883424 39.9873 2.35829 37.2545 5.02071C34.5018 7.68313 32.977 11.2266 32.977 15C32.977 18.7733 34.5018 22.3169 37.2545 24.9793C39.9873 27.6417 43.6508 29.1166 47.5718 29.1166C51.3938 29.1166 54.9782 27.7183 57.711 25.1517L54.998 22.5276C54.9782 22.5467 54.9584 22.5659 54.9386 22.5659C54.899 22.585 54.8594 22.6233 54.8396 22.6425C52.8394 24.4238 50.2651 25.4198 47.5718 25.4198C44.7004 25.4198 42.0072 24.328 39.9477 22.3552C37.908 20.3823 36.799 17.7773 36.799 14.9808C36.799 12.2035 37.908 9.57938 39.9477 7.60651C41.9874 5.63364 44.7004 4.54186 47.5718 4.54186C50.4631 4.54186 53.1563 5.61449 55.196 7.58736L56.9981 9.33038L59.7111 6.72542L57.909 4.9824C55.1366 2.35829 51.473 0.883424 47.5718 0.883424Z"
        fill="white"
      />
      <path
        d="M87.3755 15.0001C87.3755 18.8309 85.8309 22.4319 83.0188 25.1518C80.2068 27.8717 76.4839 29.3657 72.5233 29.3657C68.5429 29.3657 64.8397 27.8717 62.0277 25.1518L60.0474 23.2364L63.097 20.2867L65.0575 22.2021C67.0576 24.1175 69.7112 25.1901 72.5233 25.1901C75.3353 25.1901 77.9691 24.1175 79.9692 22.2021C81.9693 20.2867 83.0585 17.72 83.0585 15.0001C83.0585 12.2802 81.9693 9.73275 79.9692 7.79818C77.9889 5.86362 75.3551 4.81014 72.5431 4.81014C69.731 4.81014 67.0972 5.86362 65.0971 7.77903C65.0971 7.77903 65.0971 7.77903 65.0773 7.79818L62.0475 4.84845C64.8595 2.12857 68.5627 0.634552 72.5431 0.634552C76.5037 0.634552 80.2266 2.12857 83.0386 4.84845C85.8507 7.54918 87.3755 11.1501 87.3755 15.0001Z"
        fill="white"
      />
      <path
        d="M72.5433 29.5954C68.5233 29.5954 64.7211 28.0822 61.8695 25.3241L59.7109 23.2363L63.117 19.9417L65.2558 22.0295C67.2163 23.9066 69.8104 24.941 72.5433 24.941C75.2761 24.941 77.8703 23.9066 79.8109 22.0295C81.7516 20.1716 82.821 17.6624 82.821 15C82.821 12.3376 81.7516 9.84754 79.8109 7.97044C77.8901 6.09334 75.2959 5.03986 72.5433 5.03986C69.7906 5.03986 67.2163 6.07418 65.2756 7.95129L65.0775 8.14283L61.6912 4.84832L61.8695 4.67593C64.7211 1.91775 68.5035 0.404572 72.5433 0.404572C76.5831 0.404572 80.3654 1.91775 83.2171 4.67593C86.0687 7.41497 87.6331 11.0734 87.6331 15C87.6331 18.9074 86.0687 22.5659 83.2171 25.3241C80.3654 28.0822 76.5633 29.5954 72.5433 29.5954ZM60.404 23.2363L62.2061 24.9793C64.9587 27.6417 68.6223 29.1166 72.5235 29.1166C76.4246 29.1166 80.0882 27.6417 82.8408 24.9793C85.5934 22.3169 87.1182 18.7733 87.1182 15C87.1182 11.2075 85.5934 7.66397 82.8408 5.02071C80.108 2.35829 76.4444 0.883424 72.5433 0.883424C68.7213 0.883424 65.1369 2.28167 62.4041 4.84832L65.0973 7.47243C67.0974 5.61449 69.751 4.58016 72.5631 4.58016C75.4543 4.58016 78.1475 5.67195 80.1872 7.64482C82.2269 9.59854 83.3557 12.2226 83.3557 15C83.3557 17.7773 82.2269 20.4014 80.1872 22.3743C78.1475 24.3472 75.4345 25.439 72.5829 25.439C69.7114 25.439 67.0182 24.3472 64.9587 22.3743L63.1567 20.6313L60.404 23.2363Z"
        fill="white"
      />
      <path
        d="M68.5625 15.0002L65.5129 17.9499L56.9976 9.71365L60.067 6.76392L68.5625 15.0002Z"
        fill="#EE737F"
      />
      <path
        d="M65.5136 18.2945L56.6616 9.71349L60.0677 6.43814L68.9197 15L65.5136 18.2945ZM57.3547 9.71349L65.5136 17.6241L68.2266 15L60.0479 7.10853L57.3547 9.71349Z"
        fill="#EE737F"
      />
      <path
        d="M63.1167 20.2673L60.067 23.2362L51.5518 14.9999L54.5816 12.0502L63.1167 20.2673Z"
        fill="#EE737F"
      />
      <path
        d="M60.0677 23.5619L51.2158 15L54.5823 11.7055L63.454 20.2674L60.0677 23.5619ZM51.9089 15L60.0677 22.8915L62.7808 20.2674L54.6021 12.3759L51.9089 15Z"
        fill="#EE737F"
      />
    </g>
    <defs>
      <clipPath id="clip0_1253_37777">
        <rect
          width="273.143"
          height="29.1908"
          fill="white"
          transform="translate(0.856934 0.404572)"
        />
      </clipPath>
    </defs>
  </svg>
);
