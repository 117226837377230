import { styled } from '@joovence/ui/config/styled';

export const MainDesktopStyled = styled('section')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(10)
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: theme.spacing(4)
  },

  '& h1': {
    margin: 0,

    color: theme.palette.secondary.main,
    fontSize: theme.typography.h2.fontSize
  },

  '& article': {
    [theme.breakpoints.up('md')]: {
      maxWidth: 460
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 300
    },

    margin: theme.spacing(2, 0, 6, 0)
  },

  '& span': {
    fontWeight: 500
  }
}));
