import { styled } from '@joovence/ui/config/styled';

interface MainMobileStyledProps {
  active: boolean;
}

export const MainMobileStyled = styled('section', {
  shouldForwardProp: (prop) => prop !== 'active'
})<MainMobileStyledProps>(({ theme, active }) => ({
  height: 'calc(var(--vh, 1vh) * 100)',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(3),

  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  overflow: 'hidden',
  transition: theme.transitions.create(['all'], {
    duration: '0.5s',
    easing: 'ease-in-out'
  }),

  ...(!active && {
    marginTop: 'calc(var(--vh, 1vh) * -100)'
  }),

  '& h1': {
    margin: 0,

    color: theme.palette.secondary.main,
    fontSize: theme.typography.h3.fontSize
  },

  '& span': {
    fontWeight: 500
  },

  '& .MuiButtonBase-root': {
    width: 100,

    marginTop: theme.spacing(2),
    padding: `${theme.spacing(2)} !important`
  }
}));
