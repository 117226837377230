import { FC } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import { AUTH_URL } from '@/utils/config';
import { ROUTES } from '@/utils/vars';

import { ButtonStyled, DividerStyled, IntercomButtonStyled } from './Styled';

interface MainButtonsProps {
  isMobile?: boolean;
}

export const MainButtons: FC<MainButtonsProps> = ({ isMobile }) => {
  const { t } = useTranslation('welcome');
  const { push } = useRouter();

  const openIntercom = () => {
    Intercom('show');
  };

  const start = () => {
    push(ROUTES.informations);
  };

  const auth = () => {
    push(AUTH_URL);
  };

  return (
    <>
      <ButtonStyled onClick={start} variant="red">
        {t('button.start')}
      </ButtonStyled>
      <ButtonStyled onClick={auth} variant="secondary" isMobile={isMobile}>
        {t('button.login')}
      </ButtonStyled>

      {!isMobile && <DividerStyled />}

      <IntercomButtonStyled
        role="button"
        onClick={openIntercom}
        onKeyPress={openIntercom}
        tabIndex={0}
      >
        {t('button.help')}
      </IntercomButtonStyled>
    </>
  );
};

MainButtons.defaultProps = {
  isMobile: false
};
