import { Global, css } from '@joovence/ui/libExports';

export const IntercomStyled = (): JSX.Element => (
  <Global
    styles={css`
      @media (max-width: 600px) {
        .intercom-lightweight-app-launcher,
        .intercom-launcher-frame {
          display: none !important;
        }
      }
    `}
  />
);
