import { Config } from '@/types/api/config';
import { Econsult } from '@/types/api/eConsult';

import { ROUTES } from '@/utils/vars';

export const getRedirectRoute = (
  config: Config,
  eConsult: Econsult
): ROUTES => {
  const photosLength = config.menus?.['e-consult-angles'].options.length;
  const questionsLength = config.menus?.['e-consult-questions'].options.length;

  if (eConsult && Object.keys(eConsult).length) {
    const { files, questions, status } = eConsult;

    if (status === 'complete') {
      // If status is complete, go to status page
      return ROUTES.status;
    }

    // If not all photos are loaded, go to photos
    if (!files || files.length < photosLength) {
      return ROUTES.photos;

      // If not all questions are answered, go to questions
    }
    if (!questions || Object.keys(questions).length < questionsLength) {
      return ROUTES.questions;

      // Else go to informations
    }
    return ROUTES.informations;
  }
  // If form data hasn't been fetched, go to informations
  return ROUTES.welcome;
};
