import { FC } from 'react';

import { MainText } from './MainText';
import { MainButtons } from './MainButtons';
import { MainDesktopStyled } from './Styled';

export const MainDesktop: FC = () => {
  return (
    <MainDesktopStyled>
      <MainText />
      <MainButtons />
    </MainDesktopStyled>
  );
};
