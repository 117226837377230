import { styled } from '@joovence/ui/config/styled';

export const IntercomButtonStyled = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(3),

  fontSize: theme.typography.body2.fontSize,
  cursor: 'pointer',
  outline: 'none',

  [theme.breakpoints.up('sm')]: {
    color: theme.palette.grey.A700
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(2),

    color: theme.palette.primary.contrastText
  }
}));
