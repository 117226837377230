import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@joovence/ui/libExports';
import { useViewport } from '@joovence/ui/hooks/useViewport';
import { Carousel } from '@joovence/ui/components/Carousel/Carousel';
import { CarouselStep } from '@joovence/ui/components/Carousel/helpers';

import { JoovenceLogoLarge } from '@/components/common/Icons/JoovenceLogoLarge';
import { JoovenceLogoMedium } from '@/components/common/Icons/JoovenceLogoMedium';
import { ToothIcon } from '@/components/common/Icons/Welcome/ToothIcon';
import { ClockIcon } from '@/components/common/Icons/Welcome/ClockIcon';
import { ChatIcon } from '@/components/common/Icons/Welcome/ChatIcon';

import { MainDesktop } from './Main/MainDesktop';
import { MainMobile } from './Main/MainMobile';
import { MainButtons } from './Main/MainButtons';
import { RootStyled, IntercomStyled, LogoStyled } from './Styled';
import { CAROUSEL_SECONDARY_COLOR } from './helpers';

export const Welcome: FC = () => {
  const { t } = useTranslation('welcome');
  const { breakpoints } = useTheme();
  const { width, height } = useViewport({ delay: 500 });
  const [isMainMobileRead, setMainMobileRead] = useState<boolean>(false);

  const isMobile = !!width && width < breakpoints.values.sm;
  const circleSize = height && height < 650 ? 150 : 200;

  const carouselSteps: CarouselStep[] = [
    { id: 'first', icon: ToothIcon, text: t('steps.first') },
    { id: 'second', icon: ClockIcon, text: t('steps.second') },
    { id: 'third', icon: ChatIcon, text: t('steps.third') }
  ];

  const handleMainMobileRead = () => {
    setMainMobileRead(true);
  };

  return (
    <RootStyled viewportHeight={height}>
      {isMobile && (
        <MainMobile
          isMainMobileRead={isMainMobileRead}
          onMainMobileRead={handleMainMobileRead}
        />
      )}
      <Carousel
        // Key is used here to reset carousel to the first step
        key={String(isMainMobileRead)}
        steps={carouselSteps}
        header={
          <LogoStyled viewportHeight={height}>
            {isMobile ? JoovenceLogoMedium : JoovenceLogoLarge}
          </LogoStyled>
        }
        secondaryColor={CAROUSEL_SECONDARY_COLOR}
        circleSize={circleSize}
      />
      {isMobile ? <MainButtons isMobile /> : <MainDesktop />}
      <IntercomStyled />
    </RootStyled>
  );
};
