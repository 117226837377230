export const ChatIcon = (
  <svg
    width="124"
    height="115"
    viewBox="0 0 124 115"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M103 102V108.002L98.1993 104.399L78.8606 89.887C77.9559 89.9493 76.9946 90 76 90C64.0645 90 53.0936 86.4961 44.8873 80.505L44.8867 80.5045C36.665 74.4989 31 65.6615 31 55.5C31 45.3394 36.6641 36.5016 44.8874 30.5066C53.0946 24.5032 64.0657 21 76 21C87.9345 21 98.9059 24.5034 107.113 30.507C115.336 36.502 121 45.3396 121 55.5C121 67.2762 113.377 76.9561 103 82.9259V102ZM80.1453 77.7909L80.1654 77.7889L80.1856 77.7873L81.334 77.6935L82.468 77.601L83.3781 78.2836L91 84V77.5781V75.655L92.7475 74.8521L94.4702 74.0606C103.88 69.6369 109 62.5967 109 55.5C109 49.9334 105.945 44.5065 100.036 40.1925C94.1314 35.882 85.602 33 76 33C66.398 33 57.8685 35.882 51.9643 40.1925C46.0552 44.5065 43 49.9334 43 55.5C43 61.0666 46.0552 66.4935 51.9643 70.8075C57.8685 75.118 66.398 78 76 78C77.366 78 78.7463 77.9241 80.1453 77.7909Z"
      fill="white"
      stroke="currentColor"
      strokeWidth="6"
    />
    <path
      d="M49 7C37.6328 7 27.2969 10.3398 19.6562 15.9297C12.0039 21.5078 7 29.5234 7 38.5C7 49.3867 14.418 58.5977 25 64.1523V85L45.2383 69.8125C46.4688 69.9062 47.7109 70 49 70C60.3672 70 70.7031 66.6602 78.3438 61.082C85.9961 55.4922 91 47.4766 91 38.5C91 29.5234 85.9961 21.5078 78.3438 15.9297C70.7031 10.3398 60.3672 7 49 7ZM49 13C59.1719 13 68.3359 16.0469 74.8047 20.7695C81.2734 25.4922 85 31.7383 85 38.5C85 45.2617 81.2734 51.5078 74.8047 56.2305C68.3359 60.9531 59.1719 64 49 64C47.5234 64 46.0469 63.918 44.5703 63.7773L43.4219 63.6836L31 73V60.5781L29.2656 59.7812C19.2109 55.0586 13 47.1602 13 38.5C13 31.7383 16.7266 25.4922 23.1953 20.7695C29.6641 16.0469 38.8281 13 49 13Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.2656 59.7812C28.7335 59.5313 28.2121 59.2725 27.7019 59.0051C18.5709 54.2204 13 46.7018 13 38.5C13 31.7408 16.7238 25.4968 23.1881 20.7748C23.1905 20.7731 23.1929 20.7713 23.1953 20.7695L23.1965 20.7687C29.6651 16.0465 38.8287 13 49 13C59.1713 13 68.3349 16.0465 74.8036 20.7687L74.8047 20.7695C74.8071 20.7713 74.8095 20.7731 74.8119 20.7748C81.2762 25.4968 85 31.7408 85 38.5C85 45.2617 81.2734 51.5078 74.8047 56.2305L74.8028 56.2318L74.8015 56.2328C68.3329 60.9541 59.1702 64 49 64C47.897 64 46.7939 63.9542 45.6909 63.8724C45.3173 63.8447 44.9438 63.8129 44.5703 63.7773L43.4219 63.6836L31 73V60.5781L29.2656 59.7812ZM25 64.1523C24.6634 63.9757 24.33 63.7953 24 63.6113C13.9536 58.01 7 49.0404 7 38.5C7 29.5234 12.0039 21.5078 19.6562 15.9297L19.6599 15.927C27.3002 10.3388 37.6346 7 49 7C60.3654 7 70.6998 10.3388 78.3401 15.927L78.3438 15.9297C85.9961 21.5078 91 29.5234 91 38.5C91 47.4758 85.9969 55.4909 78.3457 61.0806L78.3438 61.082C70.7031 66.6602 60.3672 70 49 70C48.2431 70 47.5023 69.9677 46.7706 69.922C46.2562 69.8899 45.7463 69.8512 45.2383 69.8125L43.9852 70.7529L25 85V64.1523ZM47.3716 76.9634L18 99.0049V68.1744C7.78581 61.6344 0 51.3317 0 38.5C0 26.7612 6.54388 16.8273 15.5281 10.2765C24.4906 3.7214 36.3091 0 49 0C61.6903 0 73.5083 3.72106 82.4707 10.2756C91.4555 16.8264 98 26.7607 98 38.5C98 50.2414 91.4535 60.1744 82.4728 66.7346L82.4713 66.7357C73.5108 73.2773 61.6932 77 49 77C48.4426 77 47.8969 76.9858 47.3716 76.9634Z"
      fill="currentColor"
    />
  </svg>
);
