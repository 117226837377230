import { Button } from '@joovence/ui/components/Button';
import { styled } from '@joovence/ui/config/styled';

interface ButtonStyledProps {
  isMobile?: boolean;
}

export const ButtonStyled = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isMobile'
})<ButtonStyledProps>(({ theme, isMobile }) => ({
  '&.MuiButtonBase-root': {
    padding: `${theme.spacing(2)} !important`,

    textAlign: 'center',

    [theme.breakpoints.up('md')]: {
      width: 350,

      margin: theme.spacing(0, 2, 1, 0)
    },
    [theme.breakpoints.down('md')]: {
      width: 270,

      marginBottom: theme.spacing(1),

      lineHeight: '16px'
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${theme.spacing(4)})`,

      margin: theme.spacing(0, 2, 1, 2)
    },

    ...(isMobile && {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.contrastText
    })
  }
}));
