export const ClockIcon = (
  <svg
    width="88"
    height="100"
    viewBox="0 0 88 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44 0.272705C40.9167 0.272705 38.1761 1.55006 36.4384 3.50495C34.7007 5.45983 33.9091 7.93432 33.9091 10.3636C33.9091 11.9105 34.2718 13.4589 34.9537 14.8966C18.9225 18.9512 7 33.4576 7 50.7272C7 71.1223 23.6049 87.7272 44 87.7272C64.3951 87.7272 81 71.1223 81 50.7272C81 33.4576 69.0775 18.9512 53.0463 14.8966C53.7282 13.4589 54.0909 11.9105 54.0909 10.3636C54.0909 7.93432 53.2993 5.45983 51.5616 3.50495C49.8239 1.55006 47.0833 0.272705 44 0.272705ZM44 6.99998C45.4015 6.99998 46.0245 7.40444 46.5293 7.97228C47.034 8.54012 47.3636 9.42927 47.3636 10.3636C47.3636 11.298 47.034 12.1871 46.5293 12.7549C46.0245 13.3228 45.4015 13.7273 44 13.7273C42.5985 13.7273 41.9754 13.3228 41.4707 12.7549C40.966 12.1871 40.6364 11.298 40.6364 10.3636C40.6364 9.42927 40.966 8.54012 41.4707 7.97228C41.9754 7.40444 42.5985 6.99998 44 6.99998ZM44 20.4545C60.7596 20.4545 74.2727 33.9676 74.2727 50.7272C74.2727 67.4869 60.7596 81 44 81C27.2404 81 13.7273 67.4869 13.7273 50.7272C13.7273 33.9676 27.2404 20.4545 44 20.4545ZM40.6364 30.5454V54.0909H57.4545V47.3636H47.3636V30.5454H40.6364Z"
      fill="white"
    />
  </svg>
);
