import { styled } from '@joovence/ui/config/styled';

interface LogoStyledProps {
  viewportHeight?: number;
}

export const LogoStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'viewportHeight'
})<LogoStyledProps>(({ theme, viewportHeight }) => ({
  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(6, 0, 12, 0)
  },
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(3, 0, 6, 0),

    ...(viewportHeight && viewportHeight < 750
      ? {
          marginTop: theme.spacing(2)
        }
      : {
          marginTop: theme.spacing(4)
        })
  }
}));
