import { styled } from '@joovence/ui/config/styled';

import { CAROUSEL_SECONDARY_COLOR } from '../helpers';

interface RootStyledProps {
  viewportHeight?: number;
}

export const RootStyled = styled('main', {
  shouldForwardProp: (prop) => prop !== 'viewportHeight'
})<RootStyledProps>(({ theme, viewportHeight }) => ({
  height: 'calc(var(--vh, 1vh) * 100)',

  overflow: 'hidden',

  [theme.breakpoints.up('xl')]: {
    justifyContent: 'center'
  },

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(6)
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(4)
  },

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    alignItems: 'center',

    backgroundColor: theme.palette.common.white
  },
  [theme.breakpoints.down('sm')]: {
    width: 'calc(var(--vw, 1vw) * 100)',

    padding: 0,

    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',

    '& p': {
      marginTop: theme.spacing(2)
    }
  },

  ...(viewportHeight &&
    viewportHeight < 700 && {
      '&>div': {
        minHeight: 'unset'
      }
    }),

  // For Iphone 5/5S/SE
  ...(viewportHeight &&
    viewportHeight < 650 && {
      '& svg': {
        transform: 'scale(0.75)'
      },

      '& p': {
        fontSize: 18
      }
    }),

  '& svg': {
    color: CAROUSEL_SECONDARY_COLOR
  }
}));
