import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { LoadingScreen } from '@joovence/ui/components/LoadingScreen/LoadingScreen';

import type { Page } from '@/types/page';

import { RootState } from '@/store';

import { getRedirectRoute } from '@/utils/redirect';

import { Welcome } from '@/components/Welcome/Welcome';

const HomePage: Page = () => {
  const { push, isReady: isRouterReady, pathname: route } = useRouter();
  const config = useSelector((state: RootState) => state.config.config);
  const eConsult = useSelector((state: RootState) => state.eConsult.eConsult);

  const nextRoute = getRedirectRoute(config, eConsult);

  useEffect(() => {
    if (isRouterReady && nextRoute !== route) {
      push(nextRoute);
    }
  }, [isRouterReady, nextRoute, route]);

  return (
    <>
      <LoadingScreen visible={nextRoute !== route} />
      {nextRoute === route && <Welcome />}
    </>
  );
};

HomePage.OuterLayout = ({ children }) => children;

export default HomePage;
