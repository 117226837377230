import { FC } from 'react';

import { useTranslation, Trans } from 'react-i18next';

export const MainText: FC = () => {
  const { t } = useTranslation('welcome');

  return (
    <>
      <h1>{t('header')}</h1>

      <article>
        <p>
          <Trans t={t} i18nKey="article.first">
            Discover the eConsult, <span>a free service</span> that will let you
            know if <span>Joovence aligners are right for you.</span>
          </Trans>
        </p>
        <p>{t('article.second')}</p>
      </article>
    </>
  );
};
