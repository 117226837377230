import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@joovence/ui/components/Button';

import { MainText } from './MainText';
import { MainMobileStyled } from './Styled';

interface MainMobileProps {
  isMainMobileRead: boolean;
  onMainMobileRead: () => void;
}

export const MainMobile: FC<MainMobileProps> = ({
  isMainMobileRead,
  onMainMobileRead
}) => {
  const { t } = useTranslation('welcome');

  return (
    <MainMobileStyled active={!isMainMobileRead}>
      <MainText />
      <Button variant="red" onClick={onMainMobileRead}>
        {t('button.continue')}
      </Button>
    </MainMobileStyled>
  );
};
